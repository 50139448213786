import React from "react";
import {
  Typography,
  Grid,
  Chip,
  Avatar,
  CardHeader,
  Card,
  CardContent,
  Button,
  CardActions,
} from "@mui/material";

export default function Friends() {
  return (
    <>
      <Typography variant="h4" align="center" gutterBottom>
        Friends
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader
              avatar={<Avatar>CW</Avatar>}
              title="chiworks.studio"
              subheader="Ciera Walton"
            />
            <CardContent>
              <Chip label="3D Modeling" />
            </CardContent>
            <CardActions>
              <Button href="https://www.chiworks.studio/" target="_blank">
                Visit Website
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader
              avatar={<Avatar>PJ</Avatar>}
              title="joplin.solutions"
              subheader="Paul Joplin"
            />
            <CardContent>
              <Chip label="Web Design" />
            </CardContent>
            <CardActions>
              <Button href="https://joplin.solutions/" target="_blank">
                Visit Website
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader
              avatar={<Avatar>CH</Avatar>}
              title="chgc.weebly.com"
              subheader="Club House Gaming Clan"
            />
            <CardContent>
              <Chip label="Gaming Clan" />
            </CardContent>
            <CardActions>
              <Button href="https://chgc.weebly.com/" target="_blank">
                Visit Website
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
