import React, { createRef } from "react";
import { Link as RouterLink, MemoryRouter } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import { StaticRouter } from "react-router-dom/server";
import PropTypes from "prop-types";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LanguageIcon from '@mui/icons-material/Language';

function Router(props) {
  const { children } = props;
  if (typeof window === "undefined") {
    return <StaticRouter location="/drafts">{children}</StaticRouter>;
  }

  return (
    <MemoryRouter initialEntries={["/drafts"]} initialIndex={0}>
      {children}
    </MemoryRouter>
  );
}

Router.propTypes = {
  children: PropTypes.node,
};

function ListItemLink(props) {
  const { icon, primary, to, setDrawerState } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        onClick={() => setDrawerState(false)}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default function DrawerContent({ setDrawerState }) {
  return (
    <Box sx={{ width: "250px" }}>
      <Typography
        align="center"
        variant="h3"
        sx={{ fontFamily: "'Pacifico'", margin: "20px 20px" }}
      >
        [WS]
      </Typography>
      <Divider>Menu</Divider>
      <List>
        <ListItemLink
          to="/"
          primary="Home"
          icon={<HomeIcon />}
          setDrawerState={setDrawerState}
        />
        <li>
          <Link
            href="https://wiki.walton.solutions"
            target="_blank"
            ref={createRef()}
            underline="none"
            color="inherit"
            onClick={() => setDrawerState(false)}
          >
            <ListItem button>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="Wiki" />
            </ListItem>
          </Link>
        </li>
        <ListItemLink
          to="/Frields"
          primary="Friends"
          icon={<LanguageIcon />}
          setDrawerState={setDrawerState}
        />
      </List>
    </Box>
  );
}
