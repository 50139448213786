import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Container,
  useTheme,
  IconButton,
  Drawer,
  Link,
} from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import MenuIcon from "@mui/icons-material/Menu";
import "./App.css";
import { DarkModeContext } from "./ToggleDarkModeProvider";
import DrawerContent from "./DrawerContent";

function Navbar() {
  const colorMode = React.useContext(DarkModeContext);
  const [drawerState, setDrawerState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState(open);
  };
  const theme = useTheme();

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid container spacing={1}>
            <Grid item xs={2} sx={{ textAlign: "left" }}>
              <IconButton
                size="large"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h1"
                noWrap
                sx={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  fontFamily: "'Pacifico'",
                  fontSize: "1.4rem",
                  "@media (min-width:600px)": {
                    fontSize: "2.4rem",
                  },
                }}
              >
                <Link href="/" underline="none" color="inherit">
                  Walton Solutions
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "right" }}>
              <IconButton
                size="large"
                onClick={colorMode.toggleMode}
                color="inherit"
              >
                {theme.palette.mode === "dark" ? (
                  <LightModeIcon />
                ) : (
                  <DarkModeIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Drawer anchor="left" open={drawerState} onClose={toggleDrawer(false)}>
          <DrawerContent setDrawerState={setDrawerState} />
        </Drawer>
      </Container>
    </AppBar>
  );
}
export default Navbar;
