import React from "react";
import {
  Typography,
  Grid,
  Divider,
  Icon,
  Tooltip,
  Link,
  Card,
  CardContent,
  Button,
  CardActions,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Home() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="center" variant="h4">
            Welcome
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">About</Typography>
          <Typography sx={{ marginBottom: "2em" }}>
            Hello and welcome to my website.
            <br />
            My name is Travis Walton, and I am a full-time software developer
            from Tulsa, Oklahoma.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: "0.75rem" }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Professional
              </Typography>
              <Typography gutterBottom>
                My main focuses are designing and maintaining various types of
                websites. I am experienced with React, .NET 6 APIs, ASP MVC, and
                legacy Web Forms. I consider myself a full-stack developer, as I
                have done everything from website creation, API development, web
                server setup, and infrastructure configuration.
              </Typography>
              <Typography gutterBottom>
                My core languages are C#, VB.NET, JavaScript, HTML, and CSS. I
                am experienced with React, MUI, and Telerik libraries. I mostly
                work within the Microsoft development ecosystem.
              </Typography>
              <Typography>
                My toolbelt includes Visual Studio 2022, Visual Studio Code,
                Git, and various clients. On the SQL side: SSMS, MySQL, MariaDB,
                Oracle, and PostgreSQL.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                href="https://www.linkedin.com/in/travisdeswalton"
                target="_blank"
                rel="noreferrer"
              >
                View my LinkedIn
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: "0.75rem" }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Personal
              </Typography>
              <Typography gutterBottom>
                I have been building custom computer for over 18 years for
                family, friends, and clients along with providing IT support.
                More recently I have enjoyed tinkering with Ubuntu VMs and
                self-hosting things like Ad-blocking DNS, wikis, and other web
                apps.
              </Typography>
              <Typography gutterBottom>
                I am a gamer that enjoys genres such as action, adventure,
                strategy, and story-driven games. If I am not playing them, I am
                trying to make them as a hobbyist game developer using Unity,
                Source Engine, and RPG Maker.
              </Typography>
              <Typography>
                Other random interests include camping, hosting LAN parities,
                crypto and investing. I also love drinking coffee!
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                href="https://twitter.com/MegamanJuno"
                target="_blank"
                rel="noreferrer"
              >
                View my Twitter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            Thanks for taking the time to view my website. It's created with
            React and Mui! Have a great day!
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ margin: "25px" }}>
          <Divider />
        </Grid>
        <Grid item xs={12} align="center">
          <Tooltip title="GitHub">
            <Link
              href="https://github.com/TravisWalton"
              target="_blank"
              rel="noreferrer"
            >
              <Icon fontSize="large" sx={{ margin: " 0px 15px 0px 15px" }}>
                <GitHubIcon fontSize="large" />
              </Icon>
            </Link>
          </Tooltip>
          <Tooltip title="LinkedIn">
            <Link
              href="https://www.linkedin.com/in/travisdeswalton"
              target="_blank"
              rel="noreferrer"
            >
              <Icon fontSize="large" sx={{ margin: " 0px 15px 0px 15px" }}>
                <LinkedInIcon fontSize="large" />
              </Icon>
            </Link>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}
