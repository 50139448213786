import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const DarkModeContext = React.createContext({ toggleMode: () => {} });

function ToggleDarkModeProvider(props) {
  const [mode, setMode] = React.useState("dark");
  const colorMode = React.useMemo(
    () => ({
      toggleMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
        typography: {
          body1: {
            fontSize: "1.15rem",
          },
        },
      }),
    [mode]
  );

  return (
    <DarkModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </DarkModeContext.Provider>
  );
}
export { DarkModeContext, ToggleDarkModeProvider };
