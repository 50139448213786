import React from "react";
import "./App.css";
import Navbar from "./Navbar";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container, Box, Paper } from "@mui/material";
import { ToggleDarkModeProvider } from "./ToggleDarkModeProvider";
import Home from "./pages/Home";
import Friends from "./pages/Friends";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <ToggleDarkModeProvider>
        <CssBaseline />
        <BrowserRouter>
          <header>
            <Navbar />
          </header>
          <Container maxWidth="xl">
            <Paper elevation={3}>
              <Box sx={{ margin: "25px 0px", padding: "25px" }}>
                <Routes>
                  <Route path="/" element={<Home />} exact />
                  <Route path="/Frields" element={<Friends />} exact />
                </Routes>
              </Box>
            </Paper>
          </Container>
          <footer>
            <Footer />
          </footer>
        </BrowserRouter>
      </ToggleDarkModeProvider>
    </div>
  );
}
export default App;
